import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from "../components/Layout/layout"

// ToDo: Understand why blog-post pages are not build
class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulTcContent')
    const html = get(this.props, 'data.contentfulTcContent.content.childMarkdownRemark.html')

    return (

      <Layout
      heroURL= {post.imgLink.file.url}
      title= {post.title}
      subTitle= {post.subTitle}
      slug = {post.slug}
      >
      <section class="section" div="values">
        <div class="container is-fluid wrapper standard-margin">
        <div class="content">
          <div
           className="blog-post"
           dangerouslySetInnerHTML={{ __html: html }}
         />
        </div>
        </div>
        </section>
      </Layout>


    )
  }
}


export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    contentfulTcContent(contentful_id: {eq: $id}) {
    subTitle
    title
    imgLink {
        file {
          url
        }
      }
    content {
      childMarkdownRemark {
        html
      }
    }
    }
  }
`
